<template>
  <v-card v-if="metricsForDisplay.length" min-height="400" style="overflow:hidden" :class="wrapperCardClass"
          :flat="!elevatedCard" :outlined="!elevatedCard">
    <v-row align="center" class="font-weight-light text-h6 px-4 pt-4" no-gutters>
      <span v-for="(val, index) in selectedMetrics" :key="index">{{ metricsForDisplay[val].commonName }}<span
          class="mr-1" v-if="index < selectedMetrics.length -1">, </span></span>
      <v-spacer/>
      <v-btn-toggle color="primary" class="mr-2" dense rounded
                    v-model="dateRange"
                    mandatory
      >
        <v-btn small v-for="(option, index) in rangeSelectionOptions" :key="index" text>
          {{ option.title }}
        </v-btn>
      </v-btn-toggle>

      <v-btn depressed @click="showList = ! showList" small rounded

      >
        <v-icon small>mdi-format-list-bulleted</v-icon>

        <v-icon right :class="{ active: showList }">
          mdi-chevron-down
        </v-icon>


      </v-btn>

    </v-row>


    <v-row align="center" class="px-4 mt-3" no-gutters>
      <v-col cols="12" class="flex">

        <vue-apex-charts
            v-if="seriesProto.length > 0"
            :options="chartOptions"
            height="320"
            :series="seriesProto"
        ></vue-apex-charts>
      </v-col>

    </v-row>

    <v-expand-transition>

      <v-simple-table
          v-show="showList"
          class="flex mt-1"
          fixed-header
          :height="heightOfTable"

      >
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left text-body-2">
              Date
            </th>
            <th class="text-right text-body-2">
              Measurement
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(row, index) in referenceValuesForTable"
              :key="index"
              :class="alternatingColors(index)"
          >
            <td class="text-caption" align="left">
              {{ getDateAndTimeObjectFromTimestamp(row.timestamp).date }}
              {{ getDateAndTimeObjectFromTimestamp(row.timestamp).time }}
            </td>
            <td align="right">
              <v-chip color="grey lighten-3" class=" ml-1 text-caption" v-for="(entry, index) in row.displayValues"
                      :key="index" small>
                <v-icon :color="entry.color" small left>mdi-circle</v-icon>
                {{ entry.value }}
              </v-chip>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

    </v-expand-transition>


    <!-- <v-row v-for="metric in metricsForDisplay" :key="metric.eventType" align="center" class="px-4 mt-0" no-gutters>
      <v-col cols="12" class="flex">

        <vue-apex-charts
          :options="chartOptions"
          :id="metric.eventType"
          group="social"
          height="150"
          :series="metric.series"
        ></vue-apex-charts>
      </v-col>

    </v-row> -->


  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import HelperMixin from "@/core/mixins/HelperMixin";
import moment from "moment-timezone";
import bus from "@/core/helpers/bus";

export default {
  mixins: [HelperMixin],
  props: {
    metricsForDisplay: {
      type: Array,
      default: null
    },
    selectedMetrics: {
      type: Array,
      default: null
    },
    metricToDisplay: {
      type: Object,
      default: null
    },
    elevatedCard: {
      type: Boolean,
      default: false,
    },
    rangeSelectionOptions: {
      type: Array,
      default: null
    },
    metricReference: {
      type: Object,
      default: null,
    },
    latestValue: {
      type: String,
      default: null,
    },
    rangeStartDate: null,
  },
  components: {
    VueApexCharts,
  },
  data() {
    return {
      showList: false,
      metricsDisplayed: [],
      displayType: 0,
      dateRange: 3,
      metricName: null,
      metric: null,
      events: null,
      seriesProto: [],
      selectedToolTip: null,
      selectedDate: null,
      oneMonthArray: [],
      sixMonthArray: [],
      threeMonthArray: [],
      daysInOneMonth: 31,
      daysInThreeMonths: 93,
      daysInSixMonths: 189
    };
  },
  computed: {
    metricsForDisplayInCombinedChart() {
      let metricsSelected = []
      for (let i = 0; i < this.selectedMetrics.length; i++) {
        if (this.metricsForDisplay[this.selectedMetrics[i]]) {
          metricsSelected.push(this.metricsForDisplay[this.selectedMetrics[i]])
        }
      }
      return metricsSelected
    },
    referenceValuesForTable() {
      let referenceValues = []
      let referenceDict = {}
      this.metricsForDisplayInCombinedChart.forEach(metric => {
        metric.referenceValues.forEach(entry => {
          if (entry.timestamp >= this.rangeSelectionOptions[this.dateRange].start) {
            if (referenceDict[entry.timestamp]) {
              let entryString = metric.abbreviation + ' ' + entry.displayValue
              if (metric.units) {
                entryString = entryString + ' ' + metric.units
              }
              let value = {color: metric.colors[0], value: entryString}
              referenceDict[entry.timestamp].push(value)
            } else {
              let entryString = metric.abbreviation + ' ' + entry.displayValue
              if (metric.units) {
                entryString = entryString + ' ' + metric.units
              }
              let value = {color: metric.colors[0], value: entryString}
              referenceDict[entry.timestamp] = [value]
            }
          }
        })
      })
      let keys = Object.keys(referenceDict)
      keys.forEach(key => {
        let entry = referenceDict[key]
        let pairedValue = {timestamp: parseInt(key), displayValues: entry}
        referenceValues.push(pairedValue)
      })
      return referenceValues.sort((a, b) => b.timestamp - a.timestamp)
    },
    heightOfTable() {
      if (this.referenceValuesForTable.length > 7) {
        return 384
      }
      return null
    },
    wrapperCardClass() {
      if (this.elevatedCard) {
        return 'elevation-1 flex'
      }
      return 'flex'
    },
    bucketsForStatistics() {
      let statisticsBuckets = []
      this.rangeSelectionOptions.forEach(range => {
        let bucketHere = {range: range, cutoff: range.bucketCutoff}
        statisticsBuckets.push(bucketHere)
      })
      return statisticsBuckets
    },
    annotations() {
      // run through the serios proto and create annotations for everythign that is an alert
      let annotations = {}

      annotations.points = []
      let seriesIndex = 0
      this.seriesProto.forEach(series => {
        series.data.forEach(dataPoint => {
          if (dataPoint[2] && dataPoint[0] > this.min) {
            let point = {
              x: dataPoint[0],
              y: dataPoint[1],
              seriesIndex: seriesIndex,
              marker: {
                size: 3,
                fillColor: "#FFEC3A",
                strokeColor: "#333",
                strokeWidth: 1,
                shape: "circle",
              }
            }
            annotations.points.push(point)
          }

        })
      })
      return annotations
    },
    series() {
      if (this.seriesProto) {
        return this.seriesProto;
      }
      return [];
    },
    normalizedSeries() {
      // insert null values so that the tooltip can be shared
      // do they all jsut need to be the same length?

      let dicts = []
      this.seriesProto.forEach(series => {

        let dict = Object.fromEntries(series.data)
        dicts.push({name: series.name, dataDict: dict})
      })

      dicts.forEach(dict => {
        let keysHere = Object.keys(dict.dataDict)

        keysHere.forEach(key => {
          // run through and make sure the other dicts have this key
          dicts.forEach(innerDict => {
            if (!innerDict.dataDict[key]) {
              innerDict.dataDict[key] = null
            }
          })
        })
      })

      let series = []

      dicts.forEach(dict => {
        let data = []

        let stamps = Object.keys(dict.dataDict)

        stamps.sort(function (a, b) {
          return b - a;
        })
        stamps.forEach(stamp => {
          data.push([parseInt(stamp), dict.dataDict[stamp]])
        })
        let innerSeries = {name: dict.name, data: data}
        series.push(innerSeries)
      })
      return series
    },
    max() {
      let end = new Date();
      end.setHours(23, 59, 59, 999);
      return end.getTime()
    },
    min() {
      if (this.rangeSelectionOptions[this.dateRange]) {
        return this.rangeSelectionOptions[this.dateRange].start
      }
      return 0
    },
    colorsForChart() {
      //metricOrder: ['MEASUREMENT_BLOOD_PRESSURE', 'MEASUREMENT_PULSE', 'MEASUREMENT_BLOOD_GLUCOSE','MEASUREMENT_BODY_WEIGHT'],
      let colors = []
      if (this.metricsForDisplayInCombinedChart.length > 0) {
        this.metricsForDisplayInCombinedChart.forEach(metric => {
          metric.colors.forEach(color => {
            colors.push(color)
          })
        })
      }
      return colors
    },
    chartOptions() {
      let optionsToReturn = {
        chart: {
          width: "100%",
          type: "line",
          animations: {
            enabled: false,
          },

          zoom: {
            enabled: false,
          },
          events: {
            markerClick: (event, chartContext, config) =>
                this.selectedPoint({event, chartContext, config}),
          },
          toolbar: {
            show: false,
          },
        },
        annotations: this.annotations,
        colors: this.colorsForChart,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'round',
          width: 2,
        },
        grid: {
          borderColor: "#eeeeee",
          row: {
            colors: ["#f9f9f9", "transparent"], // takes an array which will be repeated on columns
            opacity: 0,
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            top: -10,
          },
        },
        tooltip: {
          shared: false,
          x: {
            show: true,
            formatter: (value) => this.returnFormattedDateLabel(value),
          },
          y: {
            show: true,
            title: {
              formatter: () => "",
            },
            formatter: (value, {series, seriesIndex, dataPointIndex, w}) =>
                this.returnFormattedLabel(value, {
                  series,
                  seriesIndex,
                  dataPointIndex,
                  w,
                }),
          },
          custom: ({seriesIndex, dataPointIndex}) => this.formattedTooltip({seriesIndex, dataPointIndex})

        },
        xaxis: {
          type: "datetime",
          max: this.max,
          min: this.min,
          show: true,
        },
        yaxis: {
          max: this.yMax,
          min: this.yMin,
        },
        markers: {
          size: 4,
          strokeWidth: 2,
          strokeOpacity: 1,
          hover: {
            sizeOffset: 2,
          },
        },
        legend: {
          show: false,
        },
      };
      // set the options for specific metrics here


      return optionsToReturn;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false;
    },
  },
  watch: {
    selectedMetrics() {
      if (this.metricsForDisplay.length > 0) {
        this.createGraphFromMetricsForDisplay()
      }
    },
    metricsForDisplay() {

      this.createGraphFromMetricsForDisplay()
    },
  },
  methods: {
    createGraphFromMetricsForDisplay() {
      this.metricsDisplayed = []
      this.seriesProto = []
      this.metricsForDisplayInCombinedChart.forEach(metric => {
        if (metric && !this.metricsDisplayed.includes(metric.eventType)) {
          metric.series.forEach(series => {
            // add each series to the metrics
            this.metricsDisplayed.push(metric.eventType)
            let seriesForPushing = {name: metric.eventType + '/' + series.name, data: series.data}
            this.seriesProto.push(seriesForPushing)
          })
        }
      })
    },
    selectedPoint(payload) {
      console.log('selected point')
      let metricType = this.seriesProto[payload.config.seriesIndex].name.split('/')[0]
      let correspondingMetric = this.metricsForDisplay.find(metric => metric.eventType === metricType)
      if (payload.event.type === "mouseup") {
        bus.$emit("addNoteToPoint", {
          type: "metric",
          data: correspondingMetric.referenceValues[payload.config.dataPointIndex],
        });
      }
    },
    returnFormattedDateLabel(value) {
      return this.getDateAndTimeFromTimestamp(value);
    },
    formattedTooltip({seriesIndex, dataPointIndex}) {
      let metricType = this.seriesProto[seriesIndex].name.split('/')[0]
      let correspondingMetric = this.metricsForDisplay.find(metric => metric.eventType === metricType)
      let valueText = ''
      let dateStamp = this.relativeTime(correspondingMetric.series[0].data[dataPointIndex][0])
      if (metricType === 'MEASUREMENT_BLOOD_PRESSURE') {
        this.selectedToolTip = correspondingMetric.series[0].data[dataPointIndex][1] + '/' + correspondingMetric.series[1].data[dataPointIndex][1]
        this.selectedDate = correspondingMetric.series[0].data[dataPointIndex][0]
        valueText = correspondingMetric.series[0].data[dataPointIndex][1] + '/' + correspondingMetric.series[1].data[dataPointIndex][1]
      } else {
        valueText = correspondingMetric.series[0].data[dataPointIndex][1]
      }
      let units = ''

      if (correspondingMetric.units) {
        units = correspondingMetric.units
      }

      let content = valueText
      let html = '<div style="padding:10px; font-weight:300"><div style="display:flex">'
          + correspondingMetric.commonName
          + '<span style="font-weight: 300; opacity:0.7; margin-left:5px;">'
          + dateStamp
          + '</span></div>'
          + '<div><span style="font-size:28px">'
          + content + '</span>'
          + '<span style="font-size:18px; margin-left:5px;">'
          + units
          + '</span><div></div>'
      return html
    },
    returnFormattedLabel(value, {seriesIndex, dataPointIndex}) {
      let metricType = this.seriesProto[seriesIndex].name.split('/')[0]

      let correspondingMetric = this.metricsForDisplay.find(metric => metric.eventType === metricType)
      let valueText = ''
      if (metricType === 'MEASUREMENT_BLOOD_PRESSURE') {
        this.selectedToolTip = correspondingMetric.series[0].data[dataPointIndex][1] + '/' + correspondingMetric.series[1].data[dataPointIndex][1]
        this.selectedDate = correspondingMetric.series[0].data[dataPointIndex][0]
        valueText = correspondingMetric.series[0].data[dataPointIndex][1] + '/' + correspondingMetric.series[1].data[dataPointIndex][1]

      } else {
        valueText = correspondingMetric.series[0].data[dataPointIndex][1]
      }

      // let val = this.metric.rawValues[dataPointIndex];
      // if (this.metricToDisplay.units) {
      //   this.selectedToolTip = this.metricToDisplay.series[0].data[dataPointIndex][1]
      //   this.selectedDate = this.metricToDisplay.series[0].data[dataPointIndex][0]
      //     return (
      //       value +
      //       " " +
      //       this.metricToDisplay.units
      //     );

      // }
      // handle the weird situation where if its only

      return valueText;
    },
    relativeTime(value) {
      let now = new Date().getTime()
      var Difference_In_Time = now - value;

      let days = Difference_In_Time / (3600000 * 24);

      // if tis less than three days ago use moment.
      if (value && Math.floor(days) < 2) {
        let dt = moment(value)
            .tz(moment.tz.guess())
            .format("MM/DD/YYYY h:mm a");
        if (dt.includes("12:00 am")) {
          return "Today";
        } else {
          return moment(value).tz(moment.tz.guess()).calendar();
        }
      } else if (value) {
        return this.getConversationalDateAndTimeFromTimestamp(value)
      }
      return "";
    },
  },
  mounted() {
    this.dateRange = this.rangeSelectionOptions.length - 1
    this.createGraphFromMetricsForDisplay()

    //this.seriesProto = this.metricToDisplay.series
    //this.sortTheArray()

  },
};
</script>
<style scoped>
.example {
  color: red;
}

.labelClass {
  background-color: green;
}

.cardGrid {
  background-size: 20px 20px;
  background-image: linear-gradient(to right, #f5f5f5 1px, transparent 1px),
  linear-gradient(to bottom, #f5f5f5 1px, transparent 1px);

  background-repeat: repeat;
}

.v-sheet.v-card {
  box-shadow: 0 0px 1px 1px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.softBoxShadow {
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12),
  0 2px 2px rgba(0, 0, 0, 0.12),
  0 4px 4px rgba(0, 0, 0, 0.12),
  0 8px 8px rgba(0, 0, 0, 0.12),
  0 16px 16px rgba(0, 0, 0, 0.12);
}

.apexcharts-tooltip {
  transform: translateY(-60px);
  visibility: hidden;
}

.alerted {
  background-color: #f7db03;
}

.defaultColor {
  background-color: #fafafa;
}


.arrow_box {
  position: relative;
  background: #555;
  border: 2px solid #000000;
  padding: 10px;
}

.arrow_box:after, .arrow_box:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(85, 85, 85, 0);
  border-right-color: #555;
  border-width: 10px;
  margin-top: -10px;
}

.arrow_box:before {
  border-color: rgba(0, 0, 0, 0);
  border-right-color: #000000;
  border-width: 13px;
  margin-top: -13px;
}

#chart .apexcharts-tooltip {
  color: #fff;
  transform: translateX(10px) translateY(10px);
  overflow: visible !important;
  white-space: normal !important;
}

#chart .apexcharts-tooltip span {
  padding: 5px 10px;
  display: inline-block;
}

</style>

<style scoped>
.v-icon.active {
  transform: rotate(-180deg);
}

</style>
