<template>
<!-- This should be the default patient view. super easy. -->
  <v-container class="px-0" fluid>
      <v-row no-gutters>
          <v-col cols="12" xs="12" md="4" xl="3" >
              <patient-info-detail :allowEdits="false" style="position: sticky; top: 88px;"  :showPatientContent="true"/>
          </v-col>
          <v-col cols="12" xs="12" md="8" xl="9">
              <patient-combined-charts  :minimalist="true"/>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import PatientCombinedCharts from '../components/PatientCombinedCharts.vue'
import PatientInfoDetail from '../components/PatientInfoDetail.vue'
export default {
  components: {PatientCombinedCharts, PatientInfoDetail},

}
</script>

<style>

</style>