<template>
  <v-container v-if="patient" class="pa-0" fluid>
    <v-row v-if="!isMobile" no-gutters>
      <v-col cols="12">
        <v-row v-if="patient.patientMetricList.length > 1" no-gutters>
          <metric-thumbnail
            v-for="(metric, index) in preparedMetrics"
            :picker="true"
            :included="selectedForCombinedChart.includes(index)"
            :elevatedCard="false"
            :compact="true"
            @selected="clickedThumbnail(index)"
            class="mr-4 mb-4"
            :key="index"
            :metricToDisplay="metric"
          />
        </v-row>
        <metric-detail-care-synthesis
          v-if="selectedForCombinedChart.length > 0"
          :selectedMetrics="selectedForCombinedChart"
          :metricsForDisplay="preparedMetrics"
          :elevatedCard="false"
          :rangeSelectionOptions="rangeSelectionOptions"
          :metricToDisplay="preparedMetrics[selectedMetric]"
        />
      </v-col>
      <portal to="patientFeedFirstRightPanel">
        <v-card flat color="transparent">
          <patient-activity-calendar :patient="patient" />
          <metric-detail-statistics-side-card
            v-if="preparedMetrics.length > 0"
            class="ml-5 mr-3"
            :selectedMetrics="selectedForCombinedChart"
            :metricsForDisplay="preparedMetrics"
            :rangeSelectionOptions="rangeSelectionOptions"
            :metricToDisplay="preparedMetrics[selectedMetric]"
          />
        </v-card>
      </portal>
    </v-row>

    <v-row no-gutters class="px-0" v-if="isMobile && false">
      <metric-detail-mobile
        v-if="preparedMetrics.length > 0"
        :elevatedCard="false"
        :flat="true"
        :rangeSelectionOptions="rangeSelectionOptions"
        :metricToDisplay="preparedMetrics[selectedMetric]"
      />
    </v-row>

    <v-row class="px-3" v-if="isMobile">
      <v-col cols="12">
        <v-expand-transition v-for="(metric, index) in preparedMetrics" :key="index">
          <metric-thumbnail
            @selected="selectedMetric = index"
            :elevatedCard="false"
            :mobileStyle="true"
            class="mb-4"
            :metricToDisplay="metric"
          />
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MetricThumbnail from '@/components/MetricThumbnail'
import HelperMixin from '@/core/mixins/HelperMixin'
import MetricDetailMobile from './MetricDetailMobile.vue'
import MetricDetailStatisticsSideCard from './MetricDetailStatisticsSideCard.vue'
import MetricDetailCareSynthesis from './MetricDetailCareSynthesis.vue'
import PatientActivityCalendar from './PatientActivityCalendar.vue'
import consts from '@/consts'

// import bus from '@/core/helpers/bus'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      selectedForCombinedChart: [0],
      fetchedMetric: 0,
      selectedMetric: 0,
      preparedMetrics: [],
      fetchedMetrics: [],
      displayedMetrics: [],
      rangeStartDate: 12345678,
      rangeEndDate: 1234567,
      toggle_exclusive: 2,
      toggle_exclusive_mobile: 1,
      orderedMetrics: {},
      refreshInterval: null,
      gridToggle: 0,
      expandSection: false,
      loadingMetrics: true,
    }
  },
  components: {
    MetricThumbnail,
    MetricDetailStatisticsSideCard,
    MetricDetailMobile,
    MetricDetailCareSynthesis,
    PatientActivityCalendar,
  },
  watch: {
    selectedForCombinedChart(val) {
      if (val.length === 0) {
        this.selectedForCombinedChart = [0]
      }
    },
    appTheme() {
      if (this.preparedMetrics.length === 0) {
        this.fetchedMetric = 0
        this.setMetrics()
      }
    },
  },
  methods: {
    clickedThumbnail(val) {
      if (this.selectedForCombinedChart.includes(val)) {
        // remove it from the selecteds...
        let index = this.selectedForCombinedChart.indexOf(val)
        if (index > -1) {
          this.selectedForCombinedChart.splice(index, 1)
        }
      } else {
        this.selectedForCombinedChart.push(val)
      }
    },
    ...mapActions('metrics', ['getEventsForRange']),
    ...mapActions('patient', ['generateFacesheet']),
    setAllChartsToSixMonths() {
      this.rangeStartDate = new Date().getTime() - 86400 * 185 * 1000
    },
    setAllChartsToThreeMonths() {
      this.rangeStartDate = new Date().getTime() - 86400 * 93 * 1000
    },
    setAllChartsToOneMonth() {
      this.rangeStartDate = new Date().getTime() - 86400 * 30 * 1000
    },
    titleForCard(metric) {
      if (consts.metricList[metric.name]) {
        return consts.metricList[metric.name].displayName
      }
      return metric.name
    },
    setMetrics() {
      this.metricOrder.forEach(metric => {
        this.fetchMetric(metric)
      })
      //this should make sure you only load the metrics once.
      // if (this.fetchedMetric < this.metricOrder.length) {
      //   if (
      //     !this.fetchedMetrics.includes(this.metricOrder[this.fetchedMetric])
      //   ) {
      //     this.fetchedMetrics.push(this.metricOrder[this.fetchedMetric])
      //     this.fetchMetric(this.metricOrder[this.fetchedMetric])
      //     this.fetchedMetric = this.fetchedMetric + 1
      //   } else {
      //     this.fetchedMetric = this.fetchedMetric + 1
      //   }
      // } else {
      //   this.loadingMetrics = false
      //   this.$emit('doneLoading')
      // }
    },
    fetchMetric(metric) {
      // get the last six months of data for this patient in each of the areas. only add the active metrics to the array for display
      let id = this.$route.params.id
      if (!id) {
        id = this.patient._id
      }
      this.getEventsForRange({
        id: id,
        metricName: metric,
        start: this.rangeSelectionOptions[0].start,
        end: new Date().getTime(),
      }).then(data => {
        if (data.events[0]) {
          this.preparedMetrics.push(data)
          this.preparedMetrics.sort(
            (a, b) => this.metricOrder.indexOf(a.eventType) - this.metricOrder.indexOf(b.eventType)
          )
        }
      })
    },
  },
  props: {
    minimalist: {
      type: Boolean,
      default: false,
    },
    allowSidebar: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('auth', ['configuration', 'appTheme']),
    ...mapState('patient', ['patient']),
    metricOrder() {
      // this sets the order of the metrics for patients that dont have all of them
      let toReturn = []
      let internalOrder = [
        'MEASUREMENT_BLOOD_PRESSURE',
        'MEASUREMENT_PULSE',
        'MEASUREMENT_BLOOD_GLUCOSE',
        'MEASUREMENT_BODY_WEIGHT',
        'MEASUREMENT_ECG',
      ]

      internalOrder.forEach(metric => {
        if (this.patient.patientMetricList.includes(metric)) {
          toReturn.push(metric)
        }
      })
      return toReturn
    },
    rangeSelectionOptions() {
      let options = []
      let yearStart = new Date()
      yearStart.setHours(0, 0, 0, 0)
      yearStart.setDate(yearStart.getDate() - 365)
      let year = {
        title: '1 Yr',
        start: yearStart.getTime(),
        stats: 'yearStats',
      }

      let sixMonthStart = new Date()
      sixMonthStart.setHours(0, 0, 0, 0)
      sixMonthStart.setDate(sixMonthStart.getDate() - 31 * 6)
      let sixMonths = {
        title: '6 Mo',
        start: sixMonthStart.getTime(),
        stats: 'sixMonthStats',
      }
      year.bucketCutoff = sixMonthStart.getTime()

      let threeMonthStart = new Date()
      threeMonthStart.setHours(0, 0, 0, 0)
      threeMonthStart.setDate(threeMonthStart.getDate() - 31 * 3)
      let threeMonths = {
        title: '3 Mo',
        start: threeMonthStart.getTime(),
        stats: 'threeMonthStats',
      }
      sixMonths.bucketCutoff = threeMonthStart.getTime()

      let oneMonthStart = new Date()
      oneMonthStart.setHours(0, 0, 0, 0)
      oneMonthStart.setDate(oneMonthStart.getDate() - 31)
      let oneMonth = {
        title: '1 Mo',
        start: oneMonthStart.getTime(),
        stats: 'oneMonthStats',
      }
      threeMonths.bucketCutoff = oneMonthStart.getTime()
      options.push(year)
      options.push(sixMonths)
      options.push(threeMonths)
      options.push(oneMonth)

      return options
    },
    metricCardClass() {
      if (this.isMobile) {
        return null
      }
      return 'mb-4'
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    mobileClass() {
      if (!this.isMobile) {
        return 'mx-0'
      }
      return null
    },
  },
  created() {
    this.rangeEndDate = new Date().getTime()
    this.rangeStartDate = new Date().getTime() - 86400 * 30 * 1000
  },
  mounted() {
    // go get the latest metrics for each of the displayed metrics?
    // get me six months of data for each of the metrics
    this.fetchedMetrics = []
    this.rangeEndDate = new Date().getTime()
    // set the appropriate range start
    // this.rangeStartDate = new Date().getTime() - (86400 * 3 * 1000)

    // list of metrics. go get the latest for each of these
    //setTimeout( () => this.setMetrics(), 100)
    this.setMetrics()
  },
  beforeDestroy() {},
}
</script>

<style></style>
