import { render, staticRenderFns } from "./MetricDetailMobile.vue?vue&type=template&id=5d536e49&scoped=true&"
import script from "./MetricDetailMobile.vue?vue&type=script&lang=js&"
export * from "./MetricDetailMobile.vue?vue&type=script&lang=js&"
import style0 from "./MetricDetailMobile.vue?vue&type=style&index=0&id=5d536e49&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d536e49",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VBtnToggle,VCard,VExpandTransition,VRow,VSpacer})
