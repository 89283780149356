<template>

    <v-card tile min-height="360" style="min-width:100%" flat >

      <v-row align="center" class="font-weight-light text-h5 px-4 pt-4"  no-gutters >
      <span >{{ metricToDisplay.commonName }}</span>
<v-spacer/>
      



    </v-row>

       


      

      <v-row style="position: absolute; width: 100%; margin-top: 30px" align="center" no-gutters>

     
          <vue-apex-charts 
      v-if="metricToDisplay && metricToDisplay.series && metricToDisplay.series[0].data.length > 0"
            :options="chartOptionsBackground"
            height="280"
            style="width:100%; max-width:100%"
            :series="backgroundSeries"
            :type="'area'"
          ></vue-apex-charts>
      
          
      </v-row>
      <v-row  style="position: absolute; width: 100%; margin-top: 30px" align="center" no-gutters>

     
          <vue-apex-charts 
      v-if="metricToDisplay && metricToDisplay.series && metricToDisplay.series[0].data.length > 0"
            :options="chartOptions"
            height="280"
            style="width:100%; max-width:100%"
            :series="series"
            :type="'area'"
          ></vue-apex-charts>
      
          
      </v-row>

<v-card flat tile :color="graphExtensionColor" height="120" style="margin-top: 310px;" >
      <v-row justify="center"  no-gutters>
        <v-btn-toggle rounded color="primary" 
            v-model="dateRange"
            mandatory
          >
            <v-btn v-show="index !== 0" v-for="(option, index) in rangeSelectionOptions" :key="index" text>
              {{option.title}}
            </v-btn>
          </v-btn-toggle>
      </v-row>
      
</v-card>
<v-row style="min-width:100%; margin-top:-40px" no-gutters class="px-3">
  <v-expand-transition>
<metric-thumbnail v-show="metricToDisplay && showThumb" class="flex" :elevatedCard="true" :flat="true"  :rangeSelectionOptions="rangeSelectionOptions" :metricToDisplay="metricToDisplay" />
  </v-expand-transition>
      </v-row>

    </v-card>

</template>

<script>
import VueApexCharts from "vue-apexcharts";
import HelperMixin from "@/core/mixins/HelperMixin";
import moment from "moment-timezone";
import bus from "@/core/helpers/bus";
import MetricThumbnail from './MetricThumbnail.vue';

export default {
  mixins: [HelperMixin],
  props: {
    metricToDisplay: {
      type: Object,
      default: null
    },
    elevatedCard: {
      type: Boolean,
      default: false,
    },
    rangeSelectionOptions: {
      type: Array,
      default: null
    },
    metricReference: {
      type: Object,
      default: null,
    },
    latestValue: {
      type: String,
      default: null,
    },
    rangeStartDate: null,
  },
  components: {
    VueApexCharts,
    MetricThumbnail,
  },
  data() {
    return {
      showThumb: true,
      displayType: 0,
      dateRange: null,
      metricName: null,
      metric: null,
      events: null,
      seriesProto: null,
      selectedToolTip: null,
      selectedDate: null,
      oneMonthArray: [],
      sixMonthArray: [],
      threeMonthArray: [],
      daysInOneMonth: 31,
      daysInThreeMonths: 93,
      daysInSixMonths: 189,
      chartForegroundFillColors: ['#90CAF9', '#78909C'],
      //chartBackgroundFillColors: ['#BBDEFB', '#90A4AE'],
      chartBackgroundFillColors: ['#90CAF9', '#78909C'],
      chartForegroundStrokeColors: ['#1E88E5', '#37474F']
    };
  },
  computed: {
    graphExtensionColor() {
      if (this.series[1]) {
        return this.chartBackgroundFillColors[1]
      }
      return this.chartBackgroundFillColors[0]
    },
    wrapperCardClass() {
      if (this.elevatedCard) {
        return 'elevation-1 flex'
      }
      return 'flex'
    },
    detailColor() {
      if (this.metricToDisplay.latestEvent.alert) {
        return 'yellow'
      }
      return 'grey lighten-4'
    },
    bucketsForStatistics() {
      let statisticsBuckets = []
      this.rangeSelectionOptions.forEach(range => {
        let bucketHere = {range: range, cutoff: range.bucketCutoff}
        statisticsBuckets.push(bucketHere)
      })
      return statisticsBuckets
    },
    series() {
      if (this.seriesProto) {
        console.log(this.seriesProto)
        return this.seriesProto;
      }
      return [];
    },
    max() {

      let end = new Date();


      end.setHours(23, 59, 59, 999);

      return end.getTime()
    },
    backgroundSeries() {
      if (this.seriesProto) {
        // add a value tomorrow
        let newSeries =  Array.from(this.series)
        let clonedSeries = []
        newSeries.forEach(series => {
          
          let clonedSeriesData = []
          series.data.forEach(dataPair => {
            clonedSeriesData.push([...dataPair])
          })
          let newData = [this.max, series.data[0][1]]
          clonedSeriesData.unshift(newData)
          

          clonedSeries.push({data: clonedSeriesData, name: series.name})
        })
        return clonedSeries;
      }
      return [];
    },
    min() {
      if (this.rangeSelectionOptions[this.dateRange]) {
        return this.rangeSelectionOptions[this.dateRange].start
      }
      return 0
    },
    chartDetails() {
      let stats = {average: null, high: null, low: null}
      if (this.metricToDisplay && this.metricToDisplay.stats && this.rangeSelectionOptions[this.dateRange] && this.metricToDisplay.stats[this.rangeSelectionOptions[this.dateRange].stats]) {
     
        stats = this.metricToDisplay.stats[this.rangeSelectionOptions[this.dateRange].stats]
      }

      let details = []

      if (stats.average) {
        details.push({ title: 'Average', value: stats.average + ' ' + this.metricToDisplay.units})
      }

      if (stats.highValue) {
        details.push({ title: 'High', value: stats.highValue + ' ' + this.metricToDisplay.units})
      }

      if (stats.lowValue) {
        details.push({ title: 'Low', value: stats.lowValue + ' ' + this.metricToDisplay.units})
      }

      if (stats.slope) {
        //details.push({ title: 'Trend', value: stats.slope})
        if (stats.slope > 0) {
          details.push({ title: 'Trend', value: 'Rising', icon: 'mdi-arrow-up-circle'})
        } else {
          details.push({ title: 'Trend', value: 'Falling', icon: 'mdi-arrow-down-circle'})
        }
        
      }

      // if (stats.r2) {
      //   details.push({ title: 'r-squared', value: stats.r2})
      // }

      return details

    },
    chartOptionsMini() {
      // only return the last 30 days in the upper sparkline
      let start = new Date();


      start.setHours(0, 0, 0, 0);

      start.setDate( start.getDate() - 31 )

      
      return {chart: {sparkline: {enabled: true}},
        stroke: {width: 2, curve: 'smooth'},
          tooltip: {
          enabled: false,},
          xaxis: {
          forceNiceScale: false,
          type: "datetime",
          max: new Date().getTime(),
          min: start.getTime(),
          show: false,},
        }
    },
    highlightedItem() {
      if (this.metricToDisplay) {
        return this.metricToDisplay.series[0].data.length - 1
      }
      return 1
    },
    chartOptionsBackground() {
      let optionsToReturn = {
        chart: {
          sparkline: {enabled: true},
          animations: {
            enabled: false,
            easing: "easeinout",
            speed: 400,
            animateGradually: {
              enabled: false,
              delay: 400,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 400,
            },
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        fill: {
          opacity: 1,
          type: 'solid',
          colors: this.chartBackgroundFillColors
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 0,
        },
        grid: {
          borderColor: "#eeeeee",
          row: {
            colors: ["#f9f9f9", "transparent"], // takes an array which will be repeated on columns
            opacity: 0,
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          padding: {
            top: -10,
          },
        },
        xaxis: {
          forceNiceScale: true,
          type: "datetime",
          max: this.max,
          min: this.min,
          show: true,
          labels: {
            show: false,
            maxHeight: 30,
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM yyyy",
              day: "dd MMM",
              hour: "HH:mm",
            },
            style: {
              colors: [],
              fontSize: "14px",
              fontFamily: "Source Sans Pro, Helvetica, Arial, sans-serif",
              cssClass: "apexcharts-xaxis-label",
            },
          },
          axisTicks: {
            show: false,
          },
          ticAmount: 0,
          axisBorder: {
            show: false,
            color: "#78909C",
            offsetX: 0,
            offsetY: 0,
          },
          tooltip: {
            enabled: false,
            offsetX: 0,
          },
        },
        yaxis: {
          max: this.yMax,
          min: this.yMin,
          ticAmount: 2,
          forceNiceScale: true,
          decimalsInFloat: 0,
          labels: {
            show: false,
            align: "right",
            minWidth: 0,
            maxWidth: 0,
            style: {
              colors: [],
              fontSize: "14px",
              fontFamily: "Source Sans Pro, Helvetica, Arial, sans-serif",
              cssClass: "apexcharts-yaxis-label",
            },
          },
        },
        tooltip: {
          enabled: false,
          
        },
        legend: {
          show: false,
        },
        annotations: {
          position: "back",
          yaxis: [],
        },
      };

      if (this.isMobile) {
        optionsToReturn.chart.toolbar.show = false;
        optionsToReturn.chart.zoom.enabled = false;
      }



      return optionsToReturn;
    },
    chartOptions() {
      let optionsToReturn = {
        chart: {
          sparkline: {enabled: true},
          animations: {
            enabled: false,
            easing: "easeinout",
            speed: 400,
            animateGradually: {
              enabled: false,
              delay: 400,
            },
            dynamicAnimation: {
              enabled: true,
              speed: 400,
            },
          },
          zoom: {
            enabled: false,
          },
          events: {
            markerClick: (event, chartContext, config) =>
              this.selectedPoint({ event, chartContext, config }),
            mounted: () => {
              console.log("mounted");
            },
            animationEnd: () => {
              console.log("animation ended");
            },
            beforeZoom: () => {
              this.hideDots();
            },
            updated: () => {
              this.graphUpdated();
            },
          },
          toolbar: {
            show: false,
          },
        },
        fill: {
          opacity: 1,
          type: 'solid',
          colors: this.chartForegroundFillColors
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 6,
          colors: this.chartForegroundStrokeColors
        },
        grid: {
          borderColor: "#eeeeee",
          row: {
            colors: ["#f9f9f9", "transparent"], // takes an array which will be repeated on columns
            opacity: 0,
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: -10,
          },
        },
        xaxis: {
          forceNiceScale: true,
          type: "datetime",
          max: this.max,
          min: this.min,
          show: true,
          labels: {
            show: false,
            maxHeight: 30,
            datetimeFormatter: {
              year: "yyyy",
              month: "MMM yyyy",
              day: "dd MMM",
              hour: "HH:mm",
            },
            style: {
              colors: [],
              fontSize: "14px",
              fontFamily: "Source Sans Pro, Helvetica, Arial, sans-serif",
              cssClass: "apexcharts-xaxis-label",
            },
          },
          axisTicks: {
            show: false,
          },
          ticAmount: 0,
          axisBorder: {
            show: false,
            color: "#78909C",
            offsetX: 0,
            offsetY: 0,
          },
          tooltip: {
            enabled: false,
            offsetX: 0,
          },
        },
        yaxis: {
          max: this.yMax,
          min: this.yMin,
          ticAmount: 2,
          forceNiceScale: true,
          decimalsInFloat: 0,
          labels: {
            show: false,
            align: "right",
            minWidth: 0,
            maxWidth: 0,
            style: {
              colors: [],
              fontSize: "14px",
              fontFamily: "Source Sans Pro, Helvetica, Arial, sans-serif",
              cssClass: "apexcharts-yaxis-label",
            },
          },
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          x: {
            show: true,
            formatter: (value) => this.returnFormattedDateLabel(value),
          },
          y: {
            show: true,
            title: {
              formatter: () => "",
            },
            formatter: (value, { series, seriesIndex, dataPointIndex, w }) =>
              this.returnFormattedLabel(value, {
                series,
                seriesIndex,
                dataPointIndex,
                w,
              }),
          },
        },
        markers: {
          colors: ['#ffffff'],
          strokeColors: this.chartForegroundStrokeColors,
          hover: {
            sizeOffset:8,
            fillColor: this.chartForegroundFillColors[0],
    strokeColor: this.chartForegroundFillColors[0],
          },
          size: .01,
          strokeWidth: 6,
          discrete: [{
    seriesIndex: 0,
    dataPointIndex: 0,
    fillColor: '#ffffff',
    strokeColor: this.chartForegroundStrokeColors[0],
    size: 8,
    strokeWidth: 4
  }, {
    seriesIndex: 1,
    dataPointIndex: 0,
    fillColor: '#ffffff',
    strokeColor: this.chartForegroundStrokeColors[1],
    size: 8,
    strokeWidth: 4
  }]
        },
        legend: {
          show: false,
        },
        annotations: {
          position: "back",
          yaxis: [],
        },
      };

      if (this.isMobile) {
        optionsToReturn.chart.toolbar.show = false;
        optionsToReturn.chart.zoom.enabled = false;
      }

      // hide the markers for everything but the last ones

    //   let markersInfo = {size: 10, strokeWidth: 4, discrete: []
    // }
    //   let seriesIndex = 0
    //   this.seriesProto.forEach(series => {
    //     let markerIndex = 0

    //     series.data.forEach(() => {
    //       if (markerIndex === 0) {
    //         markersInfo.discrete.push(
    //         {
    //           seriesIndex: seriesIndex,
    //           dataPointIndex: markerIndex,
    //           fillColor: '#000000',
    //           strokeColor: '#fff',
    //           size: 8
    //         }
            
    //       )
    //       }
          
    //       markerIndex = markerIndex + 1
    //     })


    //     seriesIndex = seriesIndex + 1
    //   })



      //optionsToReturn.markers.discrete = markersInfo.discrete



      return optionsToReturn;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false;
    },
  },
  watch: {
    metricToDisplay() {
      console.log('metric changes')
      this.displayType = 0
      this.metricName = this.metricToDisplay.eventType
    this.metric = this.metricToDisplay.latestEvent;
    this.seriesProto = this.metricToDisplay.series
    console.log(this.metricToDisplay.series)
    //this.sortTheArray()
    this.flashTheChart()
    }
  },
  methods: {
    flashTheChart() {
      this.showThumb = false
      setTimeout( () => this.showThumb = true, 300)
      
    },
    selectedPoint(payload) {
      if (payload.event.type === "mouseup") {
        bus.$emit("addNoteToPoint", {
          type: "metric",
          data: this.metricToDisplay.referenceValues[payload.config.dataPointIndex],
        });
      }
    },
    returnFormattedDateLabel(value) {
      return this.getDateAndTimeFromTimestamp(value);
    },
    returnFormattedLabel(value, { seriesIndex, dataPointIndex }) {
      if (this.metricName === "MEASUREMENT_BLOOD_PRESSURE" && seriesIndex === 0) {
        this.selectedToolTip = this.metricToDisplay.series[0].data[dataPointIndex][1] + '/' + this.metricToDisplay.series[1].data[dataPointIndex][1]
        this.selectedDate = this.metricToDisplay.series[0].data[dataPointIndex][0]
        return 'BP ' + this.metricToDisplay.series[0].data[dataPointIndex][1] + '/' + this.metricToDisplay.series[1].data[dataPointIndex][1]
      }

      if (this.metricName === "MEASUREMENT_BLOOD_PRESSURE" && seriesIndex === 1) {
        return null
      }
      // let val = this.metric.rawValues[dataPointIndex];
      if (this.metricToDisplay.units) {
        this.selectedToolTip = this.metricToDisplay.series[0].data[dataPointIndex][1]
        this.selectedDate = this.metricToDisplay.series[0].data[dataPointIndex][0]
          return (
            value +
            " " +
            this.metricToDisplay.units
          );
        
      }
      return value;
    },
    graphUpdated() {
    },
    relativeTime(value) {
      let now = new Date().getTime()
      var Difference_In_Time = now - value;

      let days = Difference_In_Time / (3600000 * 24);
      
      // if tis less than three days ago use moment.
      if (value && Math.floor(days) < 2) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format("MM/DD/YYYY h:mm a");
        if (dt.includes("12:00 am")) {
          return "Today";
        } else {
          return moment(value).tz(moment.tz.guess()).calendar();
        }
      } else if (value) {
        return this.getConversationalDateAndTimeFromTimestamp(value)
      }
      return "";
    },
    // sortTheArray() {
    //   this.yMin = null;
    //   this.yMax = null;
    //   this.peripheralNames = [];


    //   if (this.metric.events) {
    //     if (this.metric.events.length === 0) {
    //       this.noEventsForRange = true;
    //       this.isLoading = false;
    //     } else {
    //       this.noEventsForRange = false;
    //     }
    //     let newEvents = this.metric.events
    //     this.seriesProto = [];
    //     if (this.metricName === "MEASUREMENT_BLOOD_PRESSURE") {
    //       let sys = { name: this.metricName + "_systolic", data: [] };
    //       let di = { name: this.metricName + "_diastolic", data: [] };
    //       this.seriesProto.push(sys);
    //       this.seriesProto.push(di);
    //     } else {
    //       let met = { name: this.metricName + "_value", data: [] };
    //       this.seriesProto.push(met);
    //     }
    //     this.metric.rawValues = [];
    //     this.metric.sources = [];
    //     this.metric.total = 0;
    //     this.metric.sysTotal = 0;
    //     this.metric.diTotal = 0;
    //     this.metric.denominator = 0;
    //     this.metric.averageText = "";

    //     newEvents.forEach((element) => {
    //       console.log('another event for ' + this.metricName)
    //       // sort this into a bucket
    //       if (element.peripheralName) {
    //         if (!this.peripheralNames.includes(element.peripheralName)) {
    //           this.peripheralNames.push(element.peripheralName);
    //         }
    //       }
    //       if (element.eventData) {
    //         if (element.eventData.value || element.eventData.value === 0) {
    //           // handles everything but bp
    //           let measuredValue = element.eventData.value;
    //           if (this.metricName === "MEASUREMENT_BODY_WEIGHT") {
    //             measuredValue = Math.round(measuredValue * 2.205 * 10) / 10;
    //           }
    //           if (this.metricName === "MEASUREMENT_BODY_TEMPERATURE") {
    //             measuredValue =
    //               Math.round((measuredValue * (9 / 5) + 32) * 100) / 100;
    //           }
    //           if (this.metricName === "MEASUREMENT_BLOOD_GLUCOSE") {
    //             measuredValue = Math.round(measuredValue * 100) / 100;
    //           }

    //           this.seriesProto[0].data.push([element.timestamp, measuredValue]);
    //           this.metric.total = this.metric.total + measuredValue;
    //           this.metric.denominator = this.metric.denominator + 1;
    //           this.metric.averageText =
    //             Math.round((this.metric.total / this.metric.denominator) * 10) /
    //             10;

    //           if (!this.yMax || measuredValue > this.yMax) {
    //             this.yMax = measuredValue;
    //           }

    //           if (!this.yMin || measuredValue < this.yMin) {
    //             this.yMin = measuredValue;
    //           }
    //         } else if (
    //           element.eventData.diastolicValue ||
    //           element.eventData.systolicValue ||
    //           element.eventData.systolicValue === 0 ||
    //           element.eventData.diastolicValue === 0
    //         ) {
    //           this.metric.sysTotal =
    //             this.metric.sysTotal + element.eventData.systolicValue;
    //           this.metric.diTotal =
    //             this.metric.diTotal + element.eventData.diastolicValue;
    //           this.metric.denominator = this.metric.denominator + 1;
    //           this.metric.averageText =
    //             Math.round(
    //               (this.metric.sysTotal / this.metric.denominator) * 1
    //             ) /
    //               1 +
    //             "/" +
    //             Math.round(
    //               (this.metric.diTotal / this.metric.denominator) * 1
    //             ) /
    //               1;

    //           if (!this.yMax || element.eventData.systolicValue > this.yMax) {
    //             this.yMax = element.eventData.systolicValue;
    //           }

    //           if (!this.yMin || element.eventData.diastolicValue < this.yMin) {
    //             this.yMin = element.eventData.diastolicValue;
    //           }

    //           this.seriesProto[0].data.push([
    //             element.timestamp,
    //             element.eventData.systolicValue,
    //           ]);
    //           this.seriesProto[1].data.push([
    //             element.timestamp,
    //             element.eventData.diastolicValue,
    //           ]);


    //         } else {
    //           // this is a catch all. it really should push the actual value.
    //           // there is no value and its not systolic or diastolic
    //           this.metric.rawValues.push("Alert");
    //           if (element.peripheralName) {
    //             this.metric.sources.push(element.peripheralName);
    //           } else {
    //             this.metric.sources.push("-");
    //           }
    //           this.series[0].data.push([element.timestamp, 1]);
    //         }
    //       } else {
    //         console.log("no event data");
    //       }
    //     });

    //     // if (this.yMax < this.upperAnnotationValue) {
    //     //   this.yMax = this.upperAnnotationValue;
    //     // }

    //     // if (this.yMin > this.lowerAnnotationValue) {
    //     //   this.yMin = this.lowerAnnotationValue;
    //     // }

    //     let range = this.yMax - this.yMin;
    //     let buffer = range * 0.3;
    //     let buffer2 = range * 0.3;
    //     this.yMin = this.yMin - buffer;

    //     if (this.yMin < 0) {
    //       this.yMin = 0
    //     }
    //     this.yMax = this.yMax + buffer2;
    //     this.isLoading = false;
    //   }
    // },
  },
  mounted() {
    this.dateRange = this.rangeSelectionOptions.length - 1
    this.metricName = this.metricToDisplay.eventType
    this.metric = this.metricToDisplay.latestEvent;
    this.seriesProto = this.metricToDisplay.series
    console.log(this.metricToDisplay.series[0].data.length - 1)
    //this.sortTheArray()
    
  },
};
</script>
<style scoped>
.example {
  color: red;
}
.labelClass {
  background-color: green;
}
.cardGrid {
  background-size: 20px 20px;
  background-image:
    linear-gradient(to right, #f5f5f5 1px, transparent 1px),
    linear-gradient(to bottom, #f5f5f5 1px, transparent 1px);

background-repeat: repeat;
}
.v-sheet.v-card {
  box-shadow: 0 0px 1px 1px rgba(0,0,0,0.12);
  border-radius: 8px;
}
.softBoxShadow {
  background-color: white;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12);
}
.apexcharts-tooltip {
    transform: translateY(-60px);
    visibility: hidden;
  }
.alerted {
  background-color: #f7db03;
}
.defaultColor {
  background-color: #fafafa;
}

</style>

<style>

</style>
