<template>
  <!-- the overall card needs a width or it will disappear when a too-large-list item is expanded!-->

  <v-card v-if="patient" class="mb-3" color="transparent" tile flat>
    <v-card :color="elementColor" flat class="pb-4">
      <v-row no-gutters class="mx-3">
        <div :class="nameHeaderClass">
          <span v-if="patient">{{ patient.firstName }} {{ patient.lastName }}</span>
        </div>
      </v-row>
      <v-row no-gutters class="mx-3">
        <div :class="subeaderClass">
          <span>
            <span v-if="patient">
              {{ patient.age }} {{ patient.shortGender }} · {{ patient.displayBirthdate }} ·
              {{ patient.ownerPatientID }}
            </span>
          </span>
        </div>
      </v-row>
    </v-card>

    <slot></slot>

    <v-slide-y-transition>
      <div>
        <v-card-text v-show="patientInfoSummary != ''" class="font-weight-light text-body-1 px-3 pt-1 mt-0 pb-1 mb-0">{{
          patientInfoSummary
        }}</v-card-text>

        <v-list class="pl-0">
          <v-list-group
            class="pl-0"
            v-for="item in summaryItems"
            :key="item.title"
            v-model="item.active"
            no-action
            :append-icon="item.icon"
            @click="clickedListGroup(item)"
          >
            <template v-slot:activator>
              <v-list-item class="px-0">
                <v-list-item-icon class="mr-3 ml-0">
                  <v-icon v-if="item.iconColor" :color="item.iconColor">{{ item.action }}</v-icon>
                  <v-icon v-else>{{ item.action }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="font-weight-light">
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-row class="pl-9" v-for="(child, index) in item.items" :key="index" no-gutters>
              <v-list-item v-if="item.click" @click="handleMenuClick({ item, index })">
                <v-list-item-content>
                  <v-card flat color="transparent">
                    <div class="font-weight-medium text-subtitle-2 text--secondary">
                      <div class="font-weight-medium">{{ child.title }}</div>
                    </div>
                    <div class="caption">{{ child.detail }}</div>
                    <div class="font-weight-regular text-subtitle-2 text--disabled" v-if="child.thirdLine">
                      {{ child.thirdLine }}
                    </div>
                  </v-card>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-else>
                <v-list-item-content>
                  <v-card flat color="transparent">
                    <div class="font-weight-medium text-subtitle-2 text--secondary">
                      <div class="font-weight-medium">{{ child.title }}</div>
                    </div>
                    <div class="caption">{{ child.detail }}</div>
                    <div class="font-weight-regular text--disabled" v-if="child.thirdLine">{{ child.thirdLine }}</div>
                  </v-card>
                </v-list-item-content>
              </v-list-item>
            </v-row>
            <v-btn
              v-if="item.title.includes('Conditions') && item.items.length > 0 && allowEdits"
              color="primary"
              class="ml-10 mt-2"
              text
              @click="$refs.conditionsDialog.show = true"
            >
              <v-icon left>mdi-playlist-edit</v-icon>Add/Remove
            </v-btn>

            <v-btn
              v-if="item.title.includes('Medications') && item.items.length > 0 && allowEdits"
              color="primary"
              class="ml-10 mt-2"
              text
              @click="$refs.medicationsDialog.show = true"
            >
              <v-icon left>mdi-playlist-edit</v-icon>Add/Remove
            </v-btn>
          </v-list-group>
        </v-list>
      </div>
    </v-slide-y-transition>
    <patient-conditions-dialog ref="conditionsDialog" :showActivator="false" :listItem="true" :patient="patient" />
    <patient-medications-dialog ref="medicationsDialog" :showActivator="false" :listItem="true" :patient="patient" />
  </v-card>
</template>

<script>
import bus from '@/core/helpers/bus'
import consts from '@/consts'
import { mapState, mapActions } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'
import moment from 'moment-timezone'
import PatientConditionsDialog from './dialogs/PatientConditionsDialog.vue'
import PatientMedicationsDialog from './dialogs/PatientMedicationsDialog.vue'

export default {
  components: { PatientConditionsDialog, PatientMedicationsDialog },
  mixins: [HelperMixin],
  name: 'MobilePatientHeader',
  data() {
    return {
      patientIntegrations: [],
      patientCareTeam: [],
      chartAccessList: [],
      options: {},
      rawCollaborators: null,
      inviteList: [],
      updatingInvited: false,
      lastChartReviewOnPatientLoad: null,
    }
  },
  props: {
    allowEdits: {
      type: Boolean,
      default: true,
    },
    maxWidthOfHeaderToPreventDisplayErrors: {
      type: Number,
      default: 260,
    },
    elementColor: null,
    showPatientContent: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showPatientContent(val) {
      if (val) {
        this.lastChartReviewOnPatientLoad = this.lastChartReview
        this.getList()
        this.createDeviceList()
      }
    },
    lastChartReview(val) {
      if (!this.lastChartReviewOnPatientLoad) {
        this.lastChartReviewOnPatientLoad = val
      }
    },
  },
  computed: {
    ...mapState('patient', [
      'patients',
      'patient',
      'files',
      'isSearchingChart',
      'chartSearchResults',
      'chartSearchTerm',
      'isLoadingFiles',
      'patientLogs',
      'lastChartReview',
    ]),
    ...mapState('provider', ['localProviderList']),
    ...mapState('auth', ['user', 'rpmPartner', 'appTheme', 'partner', 'configuration']),

    //font-weight-medium text-subtitle-2
    subeaderClass() {
      if (this.isMobile) {
        return 'font-weight-medium text-subtitle-1 text--secondary'
      }
      return 'font-weight-medium text-subtitle-2 text--secondary'
    },
    nameHeaderClass() {
      if (this.isMobile) {
        return 'font-weight-bold text-h4'
      }
      return 'font-weight-light text-h5 '
    },
    isSmall() {
      return this.$vuetify.breakpoint.sm || false
    },
    isMed() {
      return this.$vuetify.breakpoint.md || false
    },
    isLg() {
      return this.$vuetify.breakpoint.lg || false
    },
    summaryItems() {
      // each item will have a title and a detail
      let protoItems = []

      if (this.chartAlerts.length > 0) {
        let chartAlerts = {
          action: 'mdi-alert-circle',
          iconColor: 'primary',
          title: 'Alerts (' + this.chartAlerts.length + ')',
          items: [],
          icon: 'mdi-chevron-down',
        }

        this.chartAlerts.forEach(alert => {
          chartAlerts.items.push({
            title: null,
            detail: alert.note,
            action: 'createNote',
            actionPayload: alert,
          })
        })
        protoItems.push(chartAlerts)
      }

      let demographics = {
        action: 'mdi-account-card-details-outline',
        title: 'Demographics',
        items: [],
        icon: 'mdi-chevron-down',
      }

      if (this.patient.displayBirthdate) {
        demographics.items.push({
          title: 'Date of Birth',
          detail: this.patient.displayBirthdate,
        })
      }
      if (this.patient.gender) {
        demographics.items.push({
          title: 'Gender',
          detail: this.patient.gender,
        })
      }

      if (this.patient.displayAddress) {
        demographics.items.push({
          title: 'Address',
          detail: this.patient.displayAddress,
        })
      }

      if (this.patient.smsPhone) {
        demographics.items.push({
          title: 'Phone for Messaging',
          detail: this.patient.smsPhone,
        })
      }

      protoItems.push(demographics)

      if (this.conditionsArray.length > 0) {
        let conditions = {
          action: 'mdi-bandage',
          title: 'Conditions (' + this.conditionsArray.length + ')',
          items: [],
          click: true,
        }

        this.conditionsArray.forEach(condition => {
          conditions.items.push({
            title: condition.icd10,
            detail: condition.description,
            action: 'createNote',
            actionPayload: condition,
          })
        })
        protoItems.push(conditions)
      } else if (this.allowEdits) {
        // the entire activator should be the conditions add dialog.
        let conditions = {
          action: 'mdi-bandage',
          title: 'Conditions (0)',
          items: [],
          click: true,
          activator: true,
          icon: 'mdi-plus',
        }
        protoItems.push(conditions)
      }

      if (this.medicationsArray.length > 0) {
        let medications = {
          action: 'mdi-pill',
          title: 'Medications (' + this.medicationsArray.length + ')',
          items: [],
          click: true,
          icon: 'mdi-chevron-down',
        }

        this.medicationsArray.forEach(medication => {
          medications.items.push({
            title: medication.name,
            detail: medication.strength,
            action: 'createNote',
            actionPayload: medication,
          })
        })
        protoItems.push(medications)
      } else {
        // the entire activator should be the conditions add dialog.
        let medications = {
          action: 'mdi-pill',
          title: 'Medications (0)',
          items: [],
          click: true,
          activator: true,
          icon: 'mdi-plus',
        }
        protoItems.push(medications)
      }

      //patientIntegrations

      if (this.patientIntegrations.length > 0) {
        let integrations = {
          action: 'mdi-devices',
          title: 'Devices (' + this.patientIntegrations.length + ')',
          items: [],
          icon: 'mdi-chevron-down',
        }

        this.patientIntegrations.forEach(integration => {
          integrations.items.push({
            title: integration.deviceType,
            detail: integration._id,
            thirdLine: integration.thirdLine,
          })
        })
        protoItems.push(integrations)
      }
      // chartAccessList.length

      if (this.chartAccessList.length > 0) {
        let careTeam = {
          action: 'mdi-account-multiple',
          title: 'Chart Access (' + this.chartAccessList.length + ')',
          items: [],
          icon: 'mdi-chevron-down',
        }

        this.chartAccessList.forEach(item => {
          let itemObject = { title: item.displayName }

          if (item.email) {
            itemObject.detail = item.email
          } else if (item.type === 'Partners') {
            itemObject.detail = 'Organization'
          }
          careTeam.items.push(itemObject)
        })
        protoItems.push(careTeam)
      }

      return protoItems
    },
    conditionsArray() {
      let conditions = []
      if (this.patient && this.patient.conditions) {
        this.patient.conditions.forEach(problem => {
          let objectProto = problem.split('|')
          let problemObject = {}
          problemObject.name = objectProto[0]
          problemObject.description = objectProto[1]
          problemObject.icd10 = objectProto[0]
          problemObject.fullItem = problem
          conditions.push(problemObject)
        })
      }
      return conditions
    },
    medicationsArray() {
      let medications = []
      if (this.patient && this.patient.medications) {
        this.patient.medications.forEach(medication => {
          let objectProto = medication.split('|')
          let medicationObject = {}
          medicationObject.name = objectProto[0]
          medicationObject.displayName = objectProto[0]
          if (objectProto[1]) {
            medicationObject.strength = objectProto[1]
            medicationObject.displayName = medicationObject.displayName + ' (' + objectProto[1] + ')'
          }
          medicationObject.fullItem = medication
          medications.push(medicationObject)
        })
      }
      return medications
    },
    chartAlerts() {
      let alerts = []
      if (this.patient.thresholdBreachAlertNotifications) {
        this.patient.thresholdBreachAlertNotifications.forEach(alert => {
          alert.icon = 'mdi-alert-circle'
          alerts.push(alert)
        })
      }

      if (this.patient.inactivityAlertNotifications) {
        this.patient.inactivityAlertNotifications.forEach(alert => {
          alert.icon = 'mdi-calendar-clock'
          alerts.push(alert)
        })
      }
      return alerts
    },
    patientInfoSummary() {
      let snippet = ''
      if (this.lastChartReviewOnPatientLoad) {
        snippet =
          this.patient.firstName +
          "'s chart was last reviewed on " +
          this.getConversationalDateAndTimeFromTimestamp(this.lastChartReviewOnPatientLoad) +
          '. '
      }

      if (this.patient.rpmMostRecentData) {
        let article = this.patient.firstName + "'s"
        if (snippet != '') {
          article = 'Her'

          if (this.patient.gender === 'MALE') {
            article = 'His'
          }
        }
        snippet =
          snippet +
          article +
          ' most recent measurement was taken on ' +
          this.getConversationalDateAndTimeFromTimestamp(this.patient.rpmMostRecentData) +
          '. '
      }
      return snippet
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    shouldAlertAMissedReading() {
      // only alert if it was more than 3 days ago?
      if (this.patient && this.patient.daysSinceLast && this.patient.daysSinceLast > 3) {
        return (
          this.patient.firstName +
          "'s last measurement was more than " +
          this.relativeTime(this.patient.rpmMostRecentData) +
          '.'
        )
      }
      return null
    },
  },
  methods: {
    ...mapActions('patient', ['getIntegrations']),
    clickedListGroup(category) {
      // go handle the item correctly
      if (category.items.length === 0) {
        // so you clicked an empty list.
        // determine if I care.
        if (category.title.includes('Conditions')) {
          //open the conditions dialog
          this.$refs.conditionsDialog.show = true
        }
        if (category.title.includes('Medications')) {
          //open the medications dialog
          this.$refs.medicationsDialog.show = true
        }
      }
    },
    handleMenuClick({ item, index }) {
      if (item.title.includes('Conditions')) {
        this.addConditionNote(item.items[index].actionPayload)
      }

      if (item.title.includes('Medications')) {
        this.addMedicationNote(item.items[index].actionPayload)
      }
    },
    addConditionNote(item) {
      bus.$emit('addNoteToPoint', { type: 'condition', data: item })
    },
    addMedicationNote(item) {
      bus.$emit('addNoteToPoint', { type: 'medication', data: item })
    },
    addEventNote(event) {
      if (event) {
        bus.$emit('addNoteToPoint', {
          type: 'metric',
          data: event,
        })
      } else {
        bus.$emit('addNoteToPoint')
      }
    },
    relativeTime(value) {
      if (value) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .fromNow()
        }
      }
      return ''
    },
    createDeviceList() {
      this.getIntegrations({ id: this.patient._id }).then(data => {
        data.forEach(connectedDevice => {
          // run through the latest events to get the kind of device
          let match = 0
          Object.keys(this.patient.latestEvents).forEach(key => {
            let event = this.patient.latestEvents[key]
            if (event.integrationID === connectedDevice._id) {
              let deviceName = consts.metricList[event.eventType].device
              let deviceBrand =
                connectedDevice.kind.charAt(0).toUpperCase() + connectedDevice.kind.slice(1).toLowerCase()
              connectedDevice.thirdLine = deviceBrand

              if (connectedDevice.deviceType != deviceName) {
                connectedDevice.deviceType = deviceName

                match = match + 1
              }
            }
          })
          if (match > 1) {
            connectedDevice.deviceType =
              connectedDevice.kind.charAt(0).toUpperCase() + connectedDevice.kind.slice(1).toLowerCase() + ' Account'
          }

          if (match === 0) {
            connectedDevice.deviceType =
              connectedDevice.kind.charAt(0).toUpperCase() + connectedDevice.kind.slice(1).toLowerCase() + ' Device'
            connectedDevice.unused = true
          }
        })
        this.patientIntegrations = data
      })
    },
  },
  mounted() {
    if (this.showPatientContent && this.patient) {
      this.createDeviceList()
    }
  },
  beforeDestroy() {},
}
</script>

<style></style>
