<template>
  <v-card class="flex pt-0" style="overflow-y:auto" tile color="transparent" flat>
    <v-divider class="mb-2" />
    <span class="text-body-1 font-weight-medium text--secondary">
      Statistics
    </span>
    <v-row class="py-2" v-for="(individualDetails, index) in chartDetails" :key="index" no-gutters>
      <v-col>
        <span class="text-subtitle-2">{{ individualDetails.title }}</span>
        <v-row
          class="py-1"
          align="center"
          no-gutters
          justify="space-between"
          v-for="(stat, index) in individualDetails.details"
          :key="index"
        >
          <span class="text-subtitle-2 font-weight-light">{{ stat.title }}</span>
          <span class="text-subtitle-2 font-weight-regular"
            ><v-icon class="mr-1 mb-1" color="grey lighten-1" small v-if="stat.icon">{{ stat.icon }}</v-icon
            >{{ stat.value }}</span
          >
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import HelperMixin from '@/core/mixins/HelperMixin'
import moment from 'moment-timezone'

export default {
  mixins: [HelperMixin],
  props: {
    metricsForDisplay: {
      type: Array,
      default: null,
    },
    selectedMetrics: {
      type: Array,
      default: null,
    },
    metricToDisplay: {
      type: Object,
      default: null,
    },
    rangeSelectionOptions: {
      type: Array,
      default: null,
    },
  },
  computed: {
    metricsForDisplayInCombinedChart() {
      let metricsSelected = []
      for (var i = 0; i < this.selectedMetrics.length; i++) {
        metricsSelected.push(this.metricsForDisplay[this.selectedMetrics[i]])
      }
      if (metricsSelected !== null) {
        return metricsSelected
      }
      return []
    },
    chartDetails() {
      let combinedChartDetails = []

      this.metricsForDisplayInCombinedChart.forEach(metricToDisplay => {
        let stats = { average: null, high: null, low: null }

        let details = []

        let nintyDayAverage = { title: '90 Day Average', value: '-' }
        if (metricToDisplay.stats['threeMonthStats'].average) {
          nintyDayAverage.value = metricToDisplay.stats['threeMonthStats'].average + ' ' + metricToDisplay.units
        }
        details.push(nintyDayAverage)

        let threeMonthAverage = { title: '30 Day Average', value: '-' }
        if (metricToDisplay.stats['oneMonthStats'].average) {
          threeMonthAverage.value = metricToDisplay.stats['oneMonthStats'].average + ' ' + metricToDisplay.units
        }
        details.push(threeMonthAverage)

        let oneMonthHigh = { title: '30 Day High', value: '-' }
        if (metricToDisplay.stats['oneMonthStats'].highValue) {
          oneMonthHigh.value = metricToDisplay.stats['oneMonthStats'].highValue + ' ' + metricToDisplay.units
        }
        details.push(oneMonthHigh)

        let oneMonthLow = { title: '30 Day Low', value: '-' }
        if (metricToDisplay.stats['oneMonthStats'].lowValue) {
          oneMonthLow.value = metricToDisplay.stats['oneMonthStats'].lowValue + ' ' + metricToDisplay.units
        }
        details.push(oneMonthLow)

        let oneMonthTrend = { title: '30 Day Trend', value: '-' }
        if (metricToDisplay.stats['oneMonthStats'].slope) {
          if (metricToDisplay.stats['oneMonthStats'].slope > 0) {
            oneMonthTrend.value = 'Rising'
            oneMonthTrend.icon = 'mdi-arrow-up-circle'
          } else {
            oneMonthTrend.value = 'Falling'
            oneMonthTrend.icon = 'mdi-arrow-down-circle'
          }
        }
        details.push(oneMonthTrend)

        if (stats.highValue) {
          details.push({
            title: 'High',
            value: stats.highValue + ' ' + metricToDisplay.units,
          })
        }

        if (stats.lowValue) {
          details.push({
            title: 'Low',
            value: stats.lowValue + ' ' + metricToDisplay.units,
          })
        }

        if (stats.slope) {
          //details.push({ title: 'Trend', value: stats.slope})
          if (stats.slope > 0) {
            details.push({
              title: 'Trend',
              value: 'Rising',
              icon: 'mdi-arrow-up-circle',
            })
          } else {
            details.push({
              title: 'Trend',
              value: 'Falling',
              icon: 'mdi-arrow-down-circle',
            })
          }
        }

        // if (stats.r2) {
        //   details.push({ title: 'r-squared', value: stats.r2})
        // }

        combinedChartDetails.push({
          title: metricToDisplay.commonName,
          details: details,
        })
      })

      return combinedChartDetails
    },
  },
  methods: {
    relativeTime(value) {
      let now = new Date().getTime()
      var Difference_In_Time = now - value

      let days = Difference_In_Time / (3600000 * 24)

      // if tis less than three days ago use moment.
      if (value && Math.floor(days) < 2) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .calendar()
        }
      } else if (value) {
        return this.getConversationalDateAndTimeFromTimestamp(value)
      }
      return ''
    },
  },
}
</script>

<style></style>
